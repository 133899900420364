import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import queryString from "query-string";
import BaseButton from "./BaseButton";
import SelectStyles from "../formcomponents/ReactSelectStyles";
import DateRangeFilter from "./DateRangeFilter";
import {
  getAppliedFilters,
  getFilterQuery,
  getQueryFiltersFromURL, localizeFilterStatus,
} from "./helpers/filterHelpers";
import classNames from "classnames";
import { useIntl } from "react-intl";
import repository from "../../core/repository";
import { FILTER_SETTINGS, FILTER_COLORS, MAIN_FILTER_STATUS_KEY } from "constants/constants";
import { FILTER_DATES } from "../../constants/constants";
import DateChips from "./DateChips";

export default function Filter() {
  const history = useHistory();
  const { search } = useLocation();
  const intl = useIntl();

  const mainFilterRef = useRef(null);
  const nestedFilterRef = useRef(null);

  const [currentMainFilter, setCurrentMainFilter] = useState("");
  const [filters, setFilters] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const allFilters = useSelector((state) => state.filters?.filterGroups || {});
  const appliedFilters = useSelector((state) =>
    getAppliedFilters(state.filters?.filterGroups)
  );

  useEffect(() => {
    const initialFilters = getQueryFiltersFromURL();
    const filterArray = Object.entries(initialFilters).map(
      ([mainFilter, nestedFilter]) => ({ mainFilter, nestedFilter })
    );
    setFilters(
      filterArray.filter(
        (f) => f.mainFilter !== FILTER_DATES.FROM && f.mainFilter !== FILTER_DATES.TO
      )
    );

    initialFilters.from
      ? setFromDate(new Date(initialFilters.from))
      : setFromDate(null);
    initialFilters.to
      ? setToDate(new Date(initialFilters.to))
      : setToDate(null);

    return () => {
      setCurrentMainFilter("");
      setFilters([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  useEffect(() => {
    if (!search) {
      setFilters([]);
      setCurrentMainFilter("");
      setFromDate(null);
      setToDate(null);
    }
  }, [search]);

  function focusSelect(ref) {
    ref.current?.focus();
  }

  function navigateOnFilterChange(updatedFilters) {
    const filteredParams = Object.fromEntries(
      Object.entries(updatedFilters).filter(([_, value]) => value)
    );

    const params = queryString.parse(history.location.search);
    delete params.page;

    if (Object.keys(filteredParams).length === 0) {
      delete params.filters;
    } else {
      params.filters = getFilterQuery(filteredParams);
    }

    history.push("?" + queryString.stringify(params));
  }

  function handleFilterRemoval(filter, filtersList) {
    return filtersList
      .filter((e) => e.mainFilter !== filter.mainFilter)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.mainFilter]: curr.nestedFilter,
        }),
        {}
      );
  }

  function handleDateChange(date, name) {
    if (name === FILTER_DATES.FROM) setFromDate(date ? new Date(date) : null);
    if (name === FILTER_DATES.TO) setToDate(date ? new Date(date) : null);

    const nonDateFilterObj = filters.reduce(
      (acc, curr) => ({ ...acc, [curr.mainFilter]: curr.nestedFilter }),
      {}
    );

    if (name === FILTER_DATES.FROM) {
      nonDateFilterObj.from = date ? formatForURL(date) : undefined;
    } else if (fromDate) {
      nonDateFilterObj.from = formatForURL(fromDate);
    }

    if (name === FILTER_DATES.TO) {
      nonDateFilterObj.to = date ? formatForURL(date) : undefined;
    } else if (toDate) {
      nonDateFilterObj.to = formatForURL(toDate);
    }

    navigateOnFilterChange(nonDateFilterObj);
  }

  function formatForURL(dateObj) {
    if (!dateObj || !(dateObj instanceof Date) || isNaN(dateObj))
      return undefined;
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function handleSelectChange(e, isMainFilter = true) {
    if (!e) {
      setFilters([]);
      setCurrentMainFilter("");
      return;
    }

    if (isMainFilter) {
      setCurrentMainFilter(e.value);
      if (!filters.some((f) => f.mainFilter === e.value)) {
        setFilters([...filters, { mainFilter: e.value, nestedFilter: "" }]);
      }
    } else {
      const updatedFilters = filters.map((f) =>
        f.mainFilter === currentMainFilter ? { ...f, nestedFilter: e.value } : f
      );
      setFilters(updatedFilters);
      const dateObj = {};
      if (fromDate) dateObj.from = formatForURL(fromDate);
      if (toDate) dateObj.to = formatForURL(toDate);
      const nonDateFilterObj = updatedFilters.reduce(
        (acc, curr) => ({ ...acc, [curr.mainFilter]: curr.nestedFilter }),
        {}
      );
      navigateOnFilterChange({ ...nonDateFilterObj, ...dateObj });
    }
  }


  function handleRemoveDate(field) {
    let updatedFromDate = fromDate;
    let updatedToDate = toDate;
  
    if (field === FILTER_DATES.FROM) {
      updatedFromDate = null;
      setFromDate(null);
    } else if (field === FILTER_DATES.TO) {
      updatedToDate = null;
      setToDate(null);
    }
  
    const nonDate = filters.reduce((acc, curr) => ({
      ...acc,
      [curr.mainFilter]: curr.nestedFilter,
    }), {});
  
    if (updatedFromDate) nonDate.from = formatForURL(updatedFromDate);
    if (updatedToDate) nonDate.to = formatForURL(updatedToDate);
  
    navigateOnFilterChange(nonDate);
  }

  function renderFilterChips() {
    const uniqueFilters = filters.reduce((acc, current) => {
      const isUniqueFilter = !acc.some(
        (item) => item.mainFilter === current.mainFilter
      );
      if (isUniqueFilter && current.nestedFilter) {
        acc.push(current);
      }
      return acc;
    }, []);

    if (uniqueFilters.length === 0 && !fromDate && !toDate) return null;

    return (
      <div className="button__chips_container">
        {uniqueFilters.map((f) => {
          const translatedText = intl.formatMessage({
            id: "filter." + f.mainFilter,
          });
          const filterTitle =
            translatedText !== "filter." + f.mainFilter
              ? translatedText
              : f.mainFilter;
          const nestedFilter =
            f.nestedFilter.toLowerCase() === "naminspection"
              ? "Inspection Americas"
              : localizeFilterStatus(f.nestedFilter, intl);

          return (
            f.mainFilter &&
            f.mainFilter !== "date" &&
            f.nestedFilter && (
              <button
                key={f.mainFilter}
                className="filter__chip"
                onClick={() => {
                  const updatedFilters = uniqueFilters.filter(
                    (filter) => filter.mainFilter !== f.mainFilter
                  );
                  setFilters(updatedFilters);
                  setCurrentMainFilter(
                    currentMainFilter === f.mainFilter ? "" : currentMainFilter
                  );
                  const removedObj = handleFilterRemoval(f, uniqueFilters);
                  if (fromDate) removedObj.from = formatForURL(fromDate);
                  if (toDate) removedObj.to = formatForURL(toDate);

                  navigateOnFilterChange(removedObj);
                }}
              >
                <span className="filter__text-title">{filterTitle}:</span>
                <span className="filter__text">{nestedFilter}</span>
              </button>
            )
          );
        })}
        <DateChips
          fromDate={fromDate}
          toDate={toDate}
          formatForURL={formatForURL}
          onRemoveFromDate={() => handleRemoveDate(FILTER_DATES.FROM)}
          onRemoveToDate={() => handleRemoveDate(FILTER_DATES.TO)}
        />
      </div>
    );
  }


  async function loadOptionsPaginated(searchInput, loadedOptions, additional) {
    setIsLoadingOptions(true);

    const pageSize = additional?.size || FILTER_SETTINGS.PAGE_SIZE;
    const offset = additional?.offset || FILTER_SETTINGS.OFFSET;
    const limit = additional?.limit || FILTER_SETTINGS.LIMIT;
    const afterKey = additional?.afterKey || null;

    try {
      if (searchInput.trim().length > 0) {
        return loadOptionsFromFilterSearch({
          searchInput,
          pageSize,
          loadedOptions,
          offset,
        });
      }

      return loadOptionsFromCaseSearch({
        searchInput,
        loadedOptions,
        pageSize,
        offset,
        limit,
        afterKey,
      });
    } catch (e) {
      console.error("Failed to load options", e);
      return {
        options: [],
        hasMore: false,
        additional: {
          search: "",
          offset: FILTER_SETTINGS.OFFSET,
          pageSize: FILTER_SETTINGS.PAGE_SIZE,
          afterKey: null,
        },
      };
    } finally {
      setIsLoadingOptions(false);
    }
  }

  async function loadOptionsFromCaseSearch({
    searchInput,
    pageSize,
    offset,
    limit,
    afterKey,
  }) {
    const response = await repository.getPaginatedFilters({
      filters: `exists:${currentMainFilter}`,
      q: currentMainFilter,
      from: offset,
      size: limit,
      order: currentMainFilter,
      afterKey,
    });

    const fetchedItems = response.data.data || [];
    const afterKeyNew = response.data.meta.afterKey ? response.data.meta.afterKey[currentMainFilter] : null;

    const newOptions = fetchedItems.map((item) => ({
      label: currentMainFilter === MAIN_FILTER_STATUS_KEY ? localizeFilterStatus(item[currentMainFilter], intl) : transformNAMinspection(item[currentMainFilter]),
      value: item[currentMainFilter],
    }));

    return {
      options: newOptions,
      hasMore: !!afterKeyNew,
      additional: {
        search: searchInput,
        offset: offset + pageSize,
        pageSize,
        limit,
        afterKey: afterKeyNew,
      },
    };
  }

  function clearDuplicateSubFilters(newOptions, loadedOptions) {
    const previouslyLoadedValues = new Set(
      loadedOptions.map((opt) => opt.value)
    );

    return newOptions.filter(
      (option) => !previouslyLoadedValues.has(option.value)
    );
  }

  async function loadOptionsFromFilterSearch({
    searchInput,
    loadedOptions,
    pageSize,
    offset,
  }) {
    const response = await repository.getFilterMultiSearch({
      query: searchInput,
      fields: currentMainFilter,
      size: pageSize,
      offset: offset,
    });

    const fetchedItems = response.data.data || [];
    const totalItemsCount = response.data.meta.total || 0;

    const newOptions = fetchedItems.map((item) => ({
      label: currentMainFilter === MAIN_FILTER_STATUS_KEY ? localizeFilterStatus(item, intl) : transformNAMinspection(item),
      value: item,
    }));

    const uniqueNewOptions = clearDuplicateSubFilters(
      newOptions,
      loadedOptions
    );

    return {
      options: uniqueNewOptions,
      hasMore: totalItemsCount !== loadedOptions.length,
      additional: {
        search: searchInput,
        offset: offset + pageSize,
        size: pageSize,
      },
    };
  }

  function dynamicStyles(baseStyles) {
    return {
      ...baseStyles,
      control: (provided, state) => ({
        ...SelectStyles.control(provided, state),
        backgroundColor: isLoadingOptions
          ? FILTER_COLORS.BACKGROUND
          : provided.backgroundColor,
        cursor: isLoadingOptions ? "wait" : "default",
      }),
      loadingIndicator: (provided) => ({
        ...provided,
        color: FILTER_COLORS.LOADING_INDICATOR,
      }),
    };
  }

  const hasAnyFilters = filters.length > 0 || appliedFilters.length > 0;

  const selectedNestedFilterValue = filters.find(
    (f) => f.mainFilter === currentMainFilter
  )?.nestedFilter;
  const nestedFilterValueObj = selectedNestedFilterValue
    ? {
        label: currentMainFilter === MAIN_FILTER_STATUS_KEY ? localizeFilterStatus(selectedNestedFilterValue, intl) : transformNAMinspection(selectedNestedFilterValue),
        value: selectedNestedFilterValue,
      }
    : null;

  function transformNAMinspection(value) {
    if (typeof value !== "string") return value;

    return value.toLowerCase() === "naminspection"
      ? "Inspection Americas"
      : value;
  }

  return (
    <div className="filter__container">
      <div className="filter__options">
        <div
          className={classNames("filter__select_wrapper", {
            "filter__select_wrapper--full-width":
              hasAnyFilters && currentMainFilter !== "date",
            "filter__select_wrapper--block": currentMainFilter === "date",
          })}
        >
          <div
            className={currentMainFilter !== "date" ? "dropdowns__wrapper" : ""}
          >
            <div className="form_control">
              <label onClick={() => focusSelect(mainFilterRef)}>
                {intl.formatMessage({ id: "filter.filters" })}
              </label>
              <Select
                ref={mainFilterRef}
                value={
                  currentMainFilter
                    ? {
                        label: intl.formatMessage({
                          id: "filter." + currentMainFilter,
                        }),
                        value: currentMainFilter,
                      }
                    : null
                }
                options={Object.keys(allFilters).map((filter) => ({
                  label: intl.formatMessage({ id: `filter.${filter}` }),
                  value: filter,
                }))}
                styles={SelectStyles}
                onChange={(e) => handleSelectChange(e)}
              />
            </div>

            {currentMainFilter !== "date" ? (
              <div className="form_control">
                <label onClick={() => focusSelect(nestedFilterRef)}>
                  {intl.formatMessage({ id: "filter.subcategory" })}
                </label>
                <AsyncPaginate
                  key={`${currentMainFilter}`}
                  ref={nestedFilterRef}
                  loadOptions={loadOptionsPaginated}
                  additional={{
                    offset: FILTER_SETTINGS.OFFSET,
                    size: FILTER_SETTINGS.PAGE_SIZE,
                    limit: FILTER_SETTINGS.LIMIT,
                    afterKey: null,
                  }}
                  value={nestedFilterValueObj}
                  styles={dynamicStyles(SelectStyles)}
                  onChange={(e) => handleSelectChange(e, false)}
                  debounceTimeout={300}
                />
              </div>
            ) : (
              <DateRangeFilter
                from={fromDate}
                to={toDate}
                onChangeFrom={(newDate) => handleDateChange(newDate, FILTER_DATES.FROM)}
                onChangeTo={(newDate) => handleDateChange(newDate, FILTER_DATES.TO)}
              />
            )}
          </div>
          {renderFilterChips()}
        </div>
        <div className="filter__actions">
          <BaseButton
            handleSubmit={() => {
              if (!hasAnyFilters) return;
              setFilters([]);
              setCurrentMainFilter("");
              setFromDate(null);
              setToDate(null);
              navigateOnFilterChange({});
            }}
            disabled={!hasAnyFilters}
            text={intl.formatMessage({ id: "filter.clearAllFilters" })}
            backgroundColor="gray"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
