import React, { useState, useEffect, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { loadCaseAndData } from "redux/thunks/caseThunks";
import { getCasesInDraft } from "./Utils/syncDataForOffline";
import { populateCases } from "redux/actions/casesActions";
import {
    getEventsGroupedByCase,
    getCasesFromCaseEvents,
    getAnswerErrors,
    formatCaseData,
    getCaseErrors,
    clearSyncedCase,
    syncCaseGroup,
    getEvents,
    removeAllImages,
} from "./Utils/syncData";
import { setHasAgreedToSync } from "redux/actions/saveComponentValueAction";
import classnames from 'classnames';
import ReactModal from "react-modal";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import BaseButton from "components/common/BaseButton";
import CasesToSyncTable from "./CasesToSyncTable";

const OfflineSyncDialog = ({
    showdialog,
    onCloseDialog,
    onSyncComplete,
    casesLoading,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isSyncComplete, setIsSyncComplete] = useState(false);
    const [casesToSync, setCasesToSync] = useState([]);
    const [syncResult, setSyncResult] = useState();
    const [urlCaseId, setUrlCaseId] = useState();
    const [triesAgain, setTriesAgain] = useState(false);
    const connection = useSelector(state => state.connection, shallowEqual);

    const showTryAgainButton = casesToSync?.some(c => c.isComplete && !c.isSyncing && (c.isSyncError || c.isConcurrencyError));

    useEffect(() => {
        const removeOfflineImages = async () => await removeAllImages()

        return () => removeOfflineImages()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const cases = await getCasesFromCaseEvents();
            setCasesToSync(cases.map(c => ({ ...c, isSyncing: false, isSyncError: false, isComplete: false })))
            setIsLoading(false);
        }
        if (showdialog) {
            fetchData().catch(error => console.log(error));
            setIsLoading(true);
            setIsSyncing(false)
            setIsSyncComplete(false);
            setSyncResult(null);

            const urlCaseId = window.location.pathname.split("/").length > 0 ? window.location.pathname.split("/").pop() : null;
            setUrlCaseId(urlCaseId);

        }
    }, [showdialog, casesLoading]);

    useEffect(() => {
        if (syncResult && syncResult.length > 0) {
            if (!syncResult[0].payload && syncResult[0].error) {
                if (syncResult[0].error.error.errorType === "ConcurrencyError") {
                    const syncedCase = casesToSync.find(c => c.id === syncResult[0].error.error.storedObject.id);
                    if (syncedCase) {
                        syncedCase.isSyncing = false;
                        syncedCase.isComplete = true;
                        syncedCase.isSyncError = true;
                    }
                }
            } else if (syncResult[0].payload) {
                const syncedCase = casesToSync.find(c => c.id === syncResult[0].payload.caseId);
                if (syncedCase) {
                    const addedCase = syncResult.find(e => e.type === "addCase");
                    if (addedCase) {
                        syncedCase.id = addedCase.result.id;
                    }
                    syncedCase.isSyncing = false;
                    syncedCase.isComplete = true;
                    syncedCase.isSyncError = syncResult.some(result => result.error);
                    syncedCase.isConcurrencyError = syncResult.some(res =>
                        res.error?.errorType === "ConcurrencyError" || res.error?.error?.errorType === "ConcurrencyError"
                    )
                    syncedCase.syncAttempts = syncResult[0].syncAttempts
                }
                setCasesToSync([...casesToSync]);
            } else {
                setCasesToSync([...casesToSync.map(c => ({ ...c, isSyncError: true, isComplete: true }))]);
            }
        }
    }, [syncResult]);

    const hasError = data => data?.some(e => {
        if (!e?.error) {
            return false
        }
        if (e?.error?.errorType === 'ConcurrencyError' || e?.error?.error?.errorType === 'ConcurrencyError') {
            return false
        }
        return true
    })

    const isSuccessfulSync = isSyncComplete && !hasError(casesToSync)

    const startSyncing = useCallback(async (attemptsToSync) => {
        const caseEvents = await getEventsGroupedByCase();
        let resultEvents = [];
        for (const caseEvent of caseEvents) {
            const currentId = caseEvent.caseId
            const groupResult = await syncCaseGroup(caseEvent, attemptsToSync);
            resultEvents = [...resultEvents, ...groupResult];

            console.log('groupresult:', groupResult)
            setSyncResult(groupResult);

            const isError = hasError(groupResult)

            if (groupResult.length > 0 && !isError) {
                await clearSyncedCase(currentId);
                console.log('clears case from events and failed cases', currentId)
            }
        }
        onSyncComplete();

        return resultEvents;
    }, []);

    const onStartSync = () => {
        setIsSyncComplete(false)
        setIsSyncing(true);
        if (showTryAgainButton) {
            setTriesAgain(true)
            setCasesToSync(casesToSync.filter(c => c.isSyncError).map(c => ({ ...c, isSyncing: true })));
        } else {
            setCasesToSync(casesToSync.filter(c => !c.isSyncError || !c.isConcurrencyError).map(c => ({ ...c, isSyncing: true })));
        }
        startSyncing(showTryAgainButton).then(result => {
            setIsSyncing(false);
            setIsSyncComplete(true);
            setTriesAgain(false)
            getCasesInDraft().then((response) => {
                dispatch(populateCases(response.data.map(d => formatCaseData(d.case))));
                if (urlCaseId) {
                    let caseId = null;
                    const newCaseEvent = result.find(event => event.type === "addCase" && event.payload && event.payload.caseId === urlCaseId);
                    if (newCaseEvent && newCaseEvent.result) {
                        caseId = newCaseEvent.result.id;
                    } else if (!isNaN(urlCaseId)) {
                        caseId = parseInt(urlCaseId);
                    }
                    if (caseId && !isNaN(caseId)) {
                        dispatch(
                            loadCaseAndData(caseId,
                                true,
                                getAnswerErrors(result),
                                getCaseErrors(result)));
                    }
                }
            });
        }).catch(error => console.log(error));
    }

    const fetchOfflineEvents = async () => {
        if (!connection) return

        const offlineEvents = await getEvents()
        onCloseDialog(offlineEvents.length > 0)
    }

    const onCloseModal = () => {
        fetchOfflineEvents()
        if (casesToSync.length > 0) {
            dispatch(setHasAgreedToSync(false))
        }
    }

    return showdialog && <ReactModal ariaHideApp={false} isOpen={showdialog} className="react-modal offline-modal" overlayClassName="react-modal-overlay">
        <div className="react-modal__header">
            <h2>{intl.formatMessage({ id: 'offline.syncDialogHeader' })}</h2>
            <button disabled={isSyncing || casesLoading} className="react-modal__close-button" onClick={onCloseModal}>
                <CircleCloseFilterSvg />
            </button>
        </div>
        <div className="react-modal__body">
            <div className="react-modal__text">
                {!isLoading && !casesLoading ?
                    <>
                        {!isSyncing && !isSyncComplete &&
                            <div>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogDataToSync1' })}</p>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogDataToSync2' })}</p>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogDataToSync3' })}</p>
                            </div>
                        }
                        {(isSyncing || triesAgain) && !isSyncComplete &&
                            <div>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogSyncing1' })}</p>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogSyncing2' })}</p>
                            </div>
                        }
                        {!isSyncing && isSyncComplete &&
                            <div>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogComplete1' })}</p>
                                <p>{intl.formatMessage({ id: 'offline.syncDialogComplete2' })}</p>
                            </div>
                        }
                        <CasesToSyncTable casesToSync={casesToSync} linkReports={isSyncComplete} />
                    </>
                    : <div className="element--is-loading element--is-loading-after" />
                }
            </div>
            <div className="react-modal__actions-container">
                {(!isSyncComplete || showTryAgainButton || triesAgain) && !casesLoading && !isSuccessfulSync &&
                    <BaseButton
                        handleSubmit={onStartSync}
                        text={intl.formatMessage({ id: 'offline.syncDialogButtonStart' })}
                        backgroundColor="green"
                        className={classnames("react-modal__action-button react-modal__action-button--wide", { "element--is-loading element--is-loading-after": isSyncing })}
                    />
                }
                {isSuccessfulSync && !triesAgain && connection &&
                    <BaseButton
                        disabled={triesAgain || !connection}
                        handleSubmit={onCloseModal}
                        text={intl.formatMessage({ id: 'offline.syncDialogButtonDone' })}
                        backgroundColor="green"
                        className="react-modal__action-button"
                    />
                }
            </div>
        </div>
    </ReactModal>
}

export default OfflineSyncDialog;
