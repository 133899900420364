import {
  enUS,
  it,
  fr,
  ru,
  pt,
  zhCN,
  zhTW,
  sv,
  es,
  de,
  ja,
  cs,
  pl,
  fi,
  tr,
  vi,
  nl,
  ko,
} from "date-fns/locale";

const localeMapping = {
  en: enUS,
  it: it,
  fr: fr,
  ru: ru,
  pt: pt,
  "zh": zhCN,
  "zh-CN": zhCN,
  "zh-HANT": zhTW,
  "se": sv,
  "jp": ja,
  sv: sv,
  es: es,
  de: de,
  ja: ja,
  cs: cs,
  pl: pl,
  fi: fi,
  tr: tr,
  vi: vi,
  nl: nl,
  ko: ko,
};

export const getLocaleFromLanguage = (language) => {
  return localeMapping[language] || enUS;
};
