import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import CustomDatePicker from "./CustomDatePicker";

const DatePickerComponent = ({
  myName,
  formComponentData,
  getValue,
  setValue,
}) => {
  const [date, setDate] = useState("");

  useEffect(() => {
    let savedDate = getValue(myName);
    if (savedDate && savedDate.dateObject) {
      const parsed = new Date(savedDate.dateObject);
      if (!isNaN(parsed)) {
        setDate(parsed);
      }
    } else if (myName === "reportDetails.reportDate") {
      setDate(new Date());
    }
  }, [myName, getValue]);

  const handleChange = (date) => {
    if (date) {
      const saveObject = {
        date: date.toLocaleDateString(),
        dateObject: date,
      };
      setValue(saveObject, myName);
      setDate(date);
    } else {
      setValue("", myName);
      setDate("");
    }
  };

  return (
    <>
      <CustomDatePicker selected={date} onChange={handleChange} />
      {formComponentData.required && (
        <div className="input-shell__message">
          <div className="input-shell__message-hint">
            <FormattedMessage id="formComponents.required" />
          </div>
        </div>
      )}
    </>
  );
};

export default DatePickerComponent;
