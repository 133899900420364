export const FILTER_SETTINGS = {
    PAGE_SIZE: 10,
    OFFSET: 0,
    LIMIT: 10
}

export const FILTER_COLORS = {
    BACKGROUND: '#b4b4b4',
    LOADING_INDICATOR: '#666',
}

export const CASE_STATUS = {
    DRAFT: "draft",
    APPROVED: "approved",
    DELETED: "deleted",
    SUBMITTED: "submitted",
    REJECTED: "rejected",
    "APPROVAL-EDITED": "approvalEdited",
};

export const FILTER_DATES = {
    FROM: "from",
    TO: "to",
}

export const MAIN_FILTER_STATUS_KEY = "status";
export const STATUS_TRANSLATION_KEY = "preview";
