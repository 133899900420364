
import { getCaseDataRepository } from "core/caseRepository/caseRepositoryFactory";

import { saveComponentValue, saveComponentValues, setAllAnswers, setAnswerToSave, removeAnswerToSave, removeAnswersToSave } from "redux/actions/saveComponentValueAction";
import { setCurrentCase } from "redux/actions/currentCaseActions";
import { setPageBreakEachBearing } from "redux/actions/pageBreakEachBearingActions";
import { setExcludeISOAppendix } from "redux/actions/excludeISOAppendixActions";
import { showSummaryAtTop } from "redux/actions/showSummaryAtTopAction";
import { setValidationErrors } from "redux/actions/saveComponentValueAction";
import { setRequiredList } from "redux/actions/requiredListActions";
import { setIsSaving } from "redux/actions/isSavingActions";
import { updateCaseForAnswers } from "./caseThunks";
import validateAnswers from "components/_Case/ButtonActions/validateCase";


export const loadAnswers = (caseId, answerErrors) => async (dispatch, getState) => {
    const response = await getCaseDataRepository(getState()).getCaseData(caseId);
    const answers = addBearingCount(response.data);
    dispatch(setAllAnswers({
        answers: answers,
        caseId: caseId,
    }));
    dispatchOnAnswers(answers, dispatch);
    if (answerErrors) {
        answerErrors.forEach(e => {
            handleAnswerError(e.errorData, dispatch, e.originalAnswer,)
        });
    }
}

export const addAnswer = (caseId, value, name, index, intl) => async (dispatch, getState) => {
    const answer = { value, name, index, isSaving: true };
    dispatch(setAnswerToSave(answer, intl));
}

export const updateAnswer = (caseId, answerId, value, name, updatedAt, index, originalAnswer, intl) => async (dispatch, getState) => {
    const answer = { id: answerId, value, name, updatedAt, index, isSaving: true };
    dispatch(setAnswerToSave(answer, intl));
}

export const saveAnswers = (caseId, answers) => async (dispatch, getState) => {
    const answersToSave = answers
        .filter(answer => {
            const answerFromStore = getState().saveComponentValue.components.find(a => a.name === answer.name);
            if (answerFromStore) {
                return !answerFromStore.isSaving
            } else {
                return true;
            }
        });

    if (answersToSave.length > 0) {
        await saveAnswersFlattened(caseId, answersToSave, dispatch, getState);
        dispatch(removeAnswersToSave(answers.map(a => a.name)));
    }
}

const saveAnswersFlattened = async (caseId, answers, dispatch, getState) => {


    dispatch(setIsSaving(true));

    const result = {
        answers: {},
        error: false
    }

    const answersToSave = answers
        .map(answer => {
            if (answer.id) {
                const answerFromStore = getState().saveComponentValue.components.find(a => a.name === answer.name);
                return { ...answer, updatedAt: answerFromStore.updatedAt, isSaving: true };
            } else {
                return { ...answer, isSaving: true };
            }
        });

    dispatch(saveComponentValues(answersToSave))

    try {
        const response = await getCaseDataRepository(getState()).updateMultipleAnswers(caseId, {
            answersToUpdate: answersToSave.filter(a => a.id).map(a => ({ answerId: a.id, answer: a })),
            answersToAdd: answersToSave.filter(a => !a.id)
        });
        dispatch(saveComponentValues(response.data.map(a => {
            if (a.error) {
                if (a.error?.error?.errorType === "ConcurrencyError") {
                    return {
                      id: a.error?.error?.storedObject.id,
                      value: a.error?.error?.storedObject.value,
                      name: a.answer.name,
                      updatedAt: a.error?.error?.storedObject.updatedAt,
                      index: a.error?.error?.storedObject.index,
                      isError: true,
                      error: a.error?.error,
                      isSaving: false,
                    };
                  }
                  
                if (a.error.errorType === "ConcurrencyError") {
                    return {
                        id: a.error.storedObject.id,
                        value: a.error.storedObject.value,
                        name: a.answer.name,
                        updatedAt: a.error.storedObject.updatedAt,
                        index: a.error.storedObject.index,
                        isError: true,
                        error: a.error,
                        isSaving: false
                    };
                } else {
                    return {
                        ...a.answer,
                        isError: true,
                        error: a.error,
                        isSaving: false
                    };
                }
            } else {
                return { ...a.answer, isSaving: false };
            }
        })));
        dispatch(setIsSaving(false));
        result.answers = response.data;

        await updateCaseForAnswers(getState().currentCase.case, result.answers.map(a => a.answer))(dispatch, getState);

    } catch (error) {
        if (error.response && error.response.data) {
            handleAnswerError(error.response.data, dispatch);
        } else {
            console.log(error);
        }
        result.answers = [];
        result.error = true;
    }

    return result;
}



export const saveAllAnswersAndValidate = (callback) => async (dispatch, getState) => {
    const componentsToSave = getState().saveComponentValue.componentsToSave;
    const returnValue = {
        unsavedChanges: false,
        validationErrors: false,
        requiredList: [],
        error: false
    }

    const answersFromStore = getState().saveComponentValue.components;
    const requiredList = getState().requiredList;
    const formtemplateId = getState().currentCase.case.formtemplateId;

    if (componentsToSave.length > 0) {

        const caseId = getState().currentCase.case.id;
        const result = await saveAnswersFlattened(caseId, componentsToSave.map(a => a.answer), dispatch, getState);
        dispatch(removeAnswersToSave(componentsToSave.map(a => a.answer.name)));
        for (const answer of result.answers) {

            if (answer.error) {
                returnValue.error = true;
                callback(returnValue);
                return null;
            }

            const existingAnswer = answersFromStore.find(a => a.name == answer.answer.name)
            if (existingAnswer) {
                existingAnswer.value = answer.answer.value;
            } else {
                answersFromStore.push(answer.answer);
            }
        }

        dispatch(removeAnswersToSave(componentsToSave.map(a => a.answer.name)));
    }

    const result = validateAnswers(answersFromStore, requiredList, formtemplateId);

    returnValue.requiredList = result.requiredList;
    returnValue.validationErrors = !result.valid;

    callback(returnValue);
}

export const resetAnswers = () => async dispatch => {
    dispatch(setAllAnswers({ answers: [], caseId: 0, }));
    dispatch(setValidationErrors([]));
    dispatch(setRequiredList([]));
}

const dispatchOnAnswers = (answers, dispatch) => {
    const pageBreakAnswer = answers.find(a => a.name === "reportDetails.PageBreakEachBearing");
    if (pageBreakAnswer) {
        dispatch(setPageBreakEachBearing(pageBreakAnswer.value === "true"));
    }
    const excludeAnswer = answers.find(a => a.name === "reportDetails.ExcludeISOAppendixInReportCheckBox");
    if (excludeAnswer) {
        dispatch(setExcludeISOAppendix(excludeAnswer.value === "true"));
    }
    const summaryAnswer = answers.find(a => a.name === "reportDetails.ShowSummaryAtTop");
    if (summaryAnswer) {
        dispatch(showSummaryAtTop(summaryAnswer.value === "true"));
    }
}

const addBearingCount = (answers) => {
    const groups = answers.filter(a => a.name.includes("bearingInvestigations.bearingInvestigation")).reduce((groups, answer) => {
        groups[answer.index] = groups[answer.index] || [];
        groups[answer.index].push(answer);
        return groups;
    }, {});

    const count = Object.keys(groups).filter(key => key !== 'null').length;

    answers.push({
        name: "bearingInvestigations.count",
        value: count === 0 ? "1" : count.toString(),
    });
    return answers;
}

export const handleAnswerError = (errorData, dispatch, originalAnswer) => {
    if (errorData.error.errorType === "ConcurrencyError" && errorData.error.type === "answer") {
        const errorAnswer = {
            id: errorData.error.storedObject.id,
            value: errorData.error.storedObject.value,
            name: originalAnswer.name,
            updatedAt: errorData.error.storedObject.updatedAt,
            index: errorData.error.storedObject.index,
            isError: true, error: errorData.error,
            isSaving: false
        };
        dispatch(saveComponentValue(errorAnswer))
        dispatch(removeAnswerToSave(originalAnswer.name));
    }
    else if (errorData.error.errorType === "ConcurrencyError" && errorData.error.type === "case") {
        const errorCase = {
            ...errorData.error.storedObject,
            isError: true,
            error: errorData.error,
        };
        dispatch(setCurrentCase(errorCase));
    } else {
        console.log(errorData);
    }
}