import { Auth } from 'aws-amplify'
import { getEvents } from 'components/_Offline/Utils/syncData'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function useOfflineBar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [showSyncBar, setShowSyncBar] = useState(false)

  const connection = useSelector(state => state.connection)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((response) => {
      setIsAuthenticated(true)
    }).catch(e => {
      setIsAuthenticated(false)
      console.error('There was an error authenticating the user.', e)
    })
  }, [connection])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const events = await getEvents()
        setShowSyncBar(events.length > 0)
      } catch (err) {
        console.error('Error fetching events:', err)
      }
    }

    if (connection || isSubmitting) {
      fetchData()
    } else {
      setShowDialog(false)
    }
  }, [connection, isSubmitting])

  const isShowingSyncbar = connection && (showSyncBar || showDialog) && isAuthenticated

  return {
    isAuthenticated,
    isSubmitting,
    setIsSubmitting,
    setShowDialog,
    isShowingSyncbar,
    setShowSyncBar,
    connection,
  }
}
