import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import DatePickerFilterComponent from "components/formcomponents/DatePickerFilterComponent";

const DateRangeFilter = ({ from, to, onChangeFrom, onChangeTo }) => {
  const intl = useIntl();

  return (
    <div className="form_control--dates">
      <label>{intl.formatMessage({ id: "reportDate" })}</label>
      <div className="datepicker__filters">
        <DatePickerFilterComponent
          selectedDate={from}
          onDateChange={onChangeFrom}
          labelClassName="date-range__date-picker_label"
          placeholder={intl.formatMessage({ id: "filter.from" })}
        />
        <DatePickerFilterComponent
          selectedDate={to}
          onDateChange={onChangeTo}
          labelClassName="date-range__date-picker_label"
          placeholder={intl.formatMessage({ id: "filter.to" })}
          allowsPastDates={false}
          minDate={from || new Date()}
        />
      </div>
    </div>
  );
};

DateRangeFilter.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  onChangeFrom: PropTypes.func.isRequired,
  onChangeTo: PropTypes.func.isRequired,
};

export default DateRangeFilter;
