import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TableCellBasic from 'components/common/TableCellBasic'
import TableCellStatus from 'components/common/TableCellStatus'
import { injectIntl } from "react-intl";

function OfflineBaseTable(props) {
  const headers = [
    { displayName: props.intl.formatMessage({ id: "baseTable.reportNumber" }), sortable: true, sortKey: 'report_number' },
    { displayName: props.intl.formatMessage({ id: "baseTable.date" }), sortable: true, sortKey: 'updated' },
    { displayName: props.intl.formatMessage({ id: "baseTable.customer" }), sortable: true, sortKey: 'customer' },
    { displayName: props.intl.formatMessage({ id: "baseTable.reportTitle" }), sortable: true, sortKey: 'title' },
    { displayName: props.intl.formatMessage({ id: "baseTable.author" }), sortable: true, sortKey: 'author' },
    { displayName: props.intl.formatMessage({ id: "baseTable.status" }), sortable: true, sortKey: 'status' },
    { displayName: "Report Type", sortable: true, sortKey: 'domain' },
  ]

  const handleClick = (rowId) => {
    const row = document.querySelector(`[data-row-id="${rowId}"]`)

    if (!row.classList.contains('table__body-row--is-active')) {
      const allTableRows = document.querySelectorAll('.table__body-row')
      allTableRows.forEach(row => {
        row.classList.remove('table__body-row--is-active')
      })

      row.classList.add('table__body-row--is-active')

      const rowOverlay = row.querySelector('.table__body-overlay-element')

      if (rowOverlay !== undefined) {
        const lastCell = row.querySelector('.table__body-cell:last-child')
        const leftMargin = `-${row.offsetWidth - lastCell.offsetWidth}px`
        rowOverlay.style.marginLeft = leftMargin
      }
    }
  }
  return (
    <table className={classnames('table', { 'table--layout-fixed': props.fixedLayout }, props.className)}>
      <thead>
        <tr className="table__header-row">
          {headers.map((header, index) =>
            <th key={index} className="table__header-cell_offline">
              {header.sortable ? (
                <button
                  className={classnames('table__header-inset',
                    { 'table__header-inset--button': header.sortable },
                    { 'table__header-inset--align-right': header.alignRight })}
                >
                  {header.displayName}
                </button>
              ) : (
                <div className={classnames('table__header-inset',
                  { 'table__header-inset--button': header.sortable },
                  { 'table__header-inset--align-right': header.alignRight })}
                >
                  {header.displayName}
                </div>
              )}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.tableData.length > 0 && (
          props.tableData
            .sort((data1, data2) => data2.createdAt?.toLowerCase().localeCompare(data1.createdAt?.toLowerCase()))
            .map((row, i) =>
              <tr className="table__body-row" key={row.id} onClick={() => handleClick(row.id)} data-row-id={row.id}>
                <TableCellBasic cellValue={String(row.id)} />
                <TableCellBasic cellValue={row.updated} noWrapClass={true} />
                <TableCellBasic cellValue={row.customer} />
                <TableCellBasic cellValue={row.title} useTruncate={true} />
                <TableCellBasic cellValue={row.author} />
                <TableCellStatus label={props.intl.formatMessage({ id: "preview." + row.status })} value={row.status} />
                <TableCellBasic
                  cellValue={row.domain === 'NAMInspection' ? 'Inspection Americas' : row.domain === 'Bart' ? 'BDA' : row.domain}
                  caseId={row.id}
                  status={row.status}
                  rowPrefix={props.rowPrefix}
                  isReportType
                />
              </tr>
            )
        )}
      </tbody>
    </table>
  )
}

OfflineBaseTable.propTypes = {
  tableData: PropTypes.array,
  tableName: PropTypes.string,
  fixedLayout: PropTypes.bool,
  defaultSortedKey: PropTypes.string,
  onClick: PropTypes.func
}

export default injectIntl(OfflineBaseTable)
