import React, { useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const ImageItem = ({ imageUrl, imageNumber, imageCaption, layout }) => {
  const intl = useIntl();

  const [forceReload, setForceReload] = useState(false);
  const connection = useSelector((state) => state.connection, shallowEqual);
  const errorCount = useRef(0);

  useEffect(() => {
    if (connection) {
      setForceReload(true); // Reload when connection is back
      errorCount.current = 0;
    }
  }, [connection]);

  useEffect(() => {
    if (forceReload) {
      setForceReload(false);
    }
  }, [forceReload]);

  const imageFigureAndNumber = `${intl.formatMessage({
    id: "case.livePreview.image.figure",
  })} ${imageNumber}`;

  return (
    <div className={"live-preview-images-item " + layout}>
      <div className="live-preview-images-item__media">
        <div className="live-preview-images-item__media-container">
          <img
            key={forceReload ? imageUrl : undefined} // Use key to force re-render when reload is triggered
            src={imageUrl}
            alt={
              imageFigureAndNumber ? imageFigureAndNumber : "live preview image"
            }
            className="live-preview-image-item__media-image"
            onError={(e) => {
              if (connection && errorCount < 3) {
                // Force reload if there's an error when back online
                errorCount.current += 1;
                e.target.src = imageUrl;
              }
            }}
          />
        </div>
      </div>

      {(imageNumber || imageCaption) && (
        <div className="live-preview-images-item__description">
          {imageNumber && (
            <span className="live-preview-images-item__media-figure">
              {imageFigureAndNumber}
            </span>
          )}
          {imageCaption}
        </div>
      )}
    </div>
  );
};

ImageItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageNumber: PropTypes.string,
  imageCaption: PropTypes.string,
  layout: PropTypes.string.isRequired,
};

export default ImageItem;
