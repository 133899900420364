import Pica from 'pica';

const MAX_INPUT_CANVAS_SAFE_SIZE = 4096;

export default function resizeImage(file, body, maxImageWidth, maxImageHeight) {
    const inputCanvas = document.createElement('canvas');
    const outputCanvas = document.createElement('canvas');
    const CONVERT_TYPES = ['image/png', 'image/bmp'];

    return new Promise(resolve => {
        const img = new Image();
        img.src = `data:${file.type};base64,${body}`;
        img.onload = () => {
            const { height: safeHeight, width: safeWidth } = calculateSafeInputCanvasSize(img.width, img.height);
            inputCanvas.width = safeWidth;
            inputCanvas.height = safeHeight;

            const ctx = inputCanvas.getContext('2d');
            ctx.drawImage(img, 0, 0, safeWidth, safeHeight);

            const aspectRatio = img.width / img.height;

            let resizeNeeded = false;
            let newWidth, newHeight;
            if (aspectRatio > 1) {
                // Landscape
                if (img.width > maxImageWidth) {
                    resizeNeeded = true;
                    newWidth = maxImageWidth;
                    newHeight = maxImageWidth / aspectRatio;
                }
            } else {
                // Portrait
                if (img.height > maxImageHeight) {
                    resizeNeeded = true;
                    newHeight = maxImageHeight;
                    newWidth = maxImageHeight * aspectRatio;
                }
            }

            if (CONVERT_TYPES.includes(file.type)) {
                resizeNeeded = true;
                if (!newWidth || !newHeight) {
                    newWidth = safeWidth;
                    newHeight = safeHeight;
                }
            }

            if (resizeNeeded) {
                outputCanvas.width = newWidth;
                outputCanvas.height = newHeight;
                const quality = file.type === 'image/bmp' || "image/png" ? 0.9 : 0.9; 
                resolve(resizeImageWithScaledCanvasAndImage(file, outputCanvas, inputCanvas, {
                    quality,
                    type: 'image/jpeg'
                }));
            } else {
                resolve(skipResizing(file, body));
            }
        };
    });
}

async function resizeImageWithScaledCanvasAndImage(file, outputCanvas, inputCanvas, options = {}) {
    const { quality = 0.7, type = 'image/jpeg' } = options;
    const pica = Pica();
    const result = await pica.resize(inputCanvas, outputCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2
    });
    const blob = await pica.toBlob(result, type, quality);
    const targetSize = 7 * 1024 * 1024; 

    while (blob.size > targetSize && quality > 0.5) {
        quality -= 0.1;
        blob = await pica.toBlob(result, type, quality);
    }

    const blobResult = await convertBlobToBinaryString(blob);
    return ({ file, result: btoa(blobResult) });
}

function skipResizing (file, body) {
    return new Promise(resolve => resolve({ file, result: body }));
}

function convertBlobToBinaryString(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        }

        reader.onabort = () => {
            reject(new Error('Reading blob aborted'));
        }

        reader.onerror = () => {
            reject(new Error('Error reading blob'));
        }

        reader.readAsBinaryString(blob);
    });
}

function calculateSafeInputCanvasSize(inputImageWidth, inputImageHeight) {
    const result = { width: inputImageWidth, height: inputImageHeight };

    if (inputImageWidth > MAX_INPUT_CANVAS_SAFE_SIZE || inputImageHeight > MAX_INPUT_CANVAS_SAFE_SIZE) {
        const aspectRatio = inputImageWidth / inputImageHeight;
        if (aspectRatio > 1) {
            result.width = MAX_INPUT_CANVAS_SAFE_SIZE;
            result.height = MAX_INPUT_CANVAS_SAFE_SIZE / aspectRatio;
            return result;
        }
        result.height = MAX_INPUT_CANVAS_SAFE_SIZE;
        result.width = MAX_INPUT_CANVAS_SAFE_SIZE * aspectRatio;
        return result;
    }
    return result;
}
