import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Profiler,
} from "react";
import {
  saveComponentValue,
  dismissAnswerError,
} from "../../redux/actions/saveComponentValueAction";
import { useSelector, shallowEqual, useDispatch, useStore } from "react-redux";
import { useIntl } from "react-intl";
import classnames from "classnames";
import _ from "lodash";
import { addAnswer, updateAnswer } from "../../redux/thunks/answerThunk";
import IconSevere from "components/svg/Severe";
import SvgSavingIndicator from "components/svg/SavingIndicator";

export const withSetAndGet = (PassedComponent) => ({ ...props }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const state = useStore().getState();

  const intl = useIntl();
  //Create https://react-redux.js.org/api/hooks#using-memoizing-selectors
  const answer = useSelector(
    (state) =>
      state.saveComponentValue.components.find((a) => a.name === props.myName),
    shallowEqual
  );
  const componentsToSave = useSelector(
    (state) =>
      state.saveComponentValue.componentsToSave,
    shallowEqual
  );

  const currentCaseId = useSelector(
    (state) => state.currentCase.case.id,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (answer) {
      setIsSaving(answer.isSaving);
      setIsError(answer.error ? true : false);
      setError(answer.error);
    }
  }, [answer]);

  const setValue = useCallback(
    (value, name) => {
      if (!value && !name) return;
      if (name === "bearingInvestigations.count") {
        dispatch(
          saveComponentValue({ name: name, value: JSON.stringify(value) })
        );
        return;
      }

      const answer = state.saveComponentValue.components.find((a) => a.name === name);  
      
      answer
        ? dispatch(
            updateAnswer(
              currentCaseId,
              answer.id,
              JSON.stringify(value),
              name,
              answer.updatedAt,
              answer.index,
              answer,
              intl
            )
          )
        : dispatch(
            addAnswer(
              currentCaseId,
              JSON.stringify(value),
              name,
              undefined,
              intl
            )
          );
    },
    [currentCaseId, answer, state]
  );

  const getValue = useCallback((name) => {
    let answerToSet;
    // return data from "componentsToSave" state if there are unsaved user changes,
    // otherwise take the answer data that's been saved already.
    let componentToSave = componentsToSave.find(component => component.answer.name == props.myName);
    if (componentToSave && componentToSave.answer.value) {
      answerToSet = JSON.parse(componentToSave.answer.value);
    } else if (answer && answer.value) {
      answerToSet = JSON.parse(answer.value);
    }
    return answerToSet;
  }, [answer, componentsToSave]);

  const getErrorTitle = () => {
    if (error && error.errorType === "ConcurrencyError") {
      return intl.formatMessage({ id: "saveError.concurrency" });
    } else {
      return intl.formatMessage({ id: "saveError.unknown" });
    }
  };

  const dismissMessage = () => {
    if (error && error.storedObject) {
      dispatch(dismissAnswerError(error.storedObject.id));
    }
  };

  const translateDescriptionAndPlaceholderForFormComponent = useCallback(
    (formComponentData) => {
      return {
        ...formComponentData,
        isDisabled: isSaving ? true : formComponentData.isDisabled,
        placeholder:
          formComponentData.placeholder !== undefined
            ? intl.formatMessage({ id: `placeholder.${formComponentData.key}` })
            : formComponentData.placeholder,
        description:
          formComponentData.description !== undefined
            ? intl.formatMessage({ id: formComponentData.key })
            : formComponentData.description,
        editTitle:
          formComponentData.editTitle !== undefined
            ? intl.formatMessage({ id: `editTitle.${formComponentData.key}` })
            : formComponentData.editTitle,
        revertTitle:
          formComponentData.revertTitle !== undefined
            ? intl.formatMessage({ id: `revertTitle.${formComponentData.key}` })
            : formComponentData.revertTitle,
      };
    }
  );

  return (
    <div
      data-validation-id={props.myName}
      className={classnames(isError ? "field-error" : "")}
      onClick={dismissMessage}
    >
      <ValidationListner
        myName={props.myName}
        formComponentData={props.formComponentData}
      />
      {isError && (
        <div className="field-error__overlay">
          <IconSevere title={getErrorTitle()} />
        </div>
      )}

      <div className={classnames(isSaving ? "field-saving" : "")}>
        {isSaving && (
          <div className="field-saving__overlay">
            <SvgSavingIndicator />
          </div>
        )}
      </div>

      <PassedComponent
        {...props}
        dispatch={dispatch}
        formComponentData={translateDescriptionAndPlaceholderForFormComponent(
          props.formComponentData
        )}
        setValue={setValue}
        getValue={getValue}
        isvalid={true}
      />
    </div>
  );
};

const ValidationListner = ({ myName, formComponentData }) => {
  const intl = useIntl();
  const required = intl.formatMessage({ id: 'formComponents.required' });
  const validationRequired = useSelector(state => state.saveComponentValue.validationErrors)
  const componentsToSave = useSelector(state => state.saveComponentValue.componentsToSave)

  const isValid = useSelector((state) =>
    state.saveComponentValue.validationErrors.filter((ve) =>
      formComponentData.type === "nested" || formComponentData.required
        ? ve.key.startsWith(myName)
        : ve.key === myName
    )
      .every((ve) => ve.valid),
    shallowEqual
  );

  useEffect(() => {
    const targetElement = document.querySelector(`[data-validation-id="${myName}"]`);
    if (targetElement && validationRequired?.length > 0) {
      setValidationStyle(targetElement, isValid, required);
    }
  }, [isValid, myName, componentsToSave]);

  return null;
};

function setValidationStyle(element, isValid, required) {
  const children = Array.from(element.children);

  children.map(child => {
    const { innerText, outerText } = child;

    if (!innerText.includes(required) && !outerText.includes(required)) {
      return;
    }

    return isValid
      ? child.className = child.className
        .split('invalid')
        .join()
      : child.className = `${element.className} invalid`;
  })
}