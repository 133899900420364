import React from "react";
import { useIntl } from "react-intl";

export default function DateChips({
  fromDate,
  toDate,
  formatForURL,
  onRemoveFromDate,
  onRemoveToDate,
}) {
  const intl = useIntl();

  return (
    <>
      {fromDate && (
        <button
          key="from-date"
          className="filter__chip"
          onClick={onRemoveFromDate}
        >
          <span className="filter__text">
            <span className="filter__text-title">
              {intl.formatMessage({ id: "filter.from" })}:
            </span>{" "}
            {formatForURL(fromDate)}
          </span>
        </button>
      )}
      {toDate && (
        <button key="to-date" className="filter__chip" onClick={onRemoveToDate}>
          <span className="filter__text">
            <span className="filter__text-title">
              {intl.formatMessage({ id: "filter.to" })}:
            </span>{" "}
            {formatForURL(toDate)}
          </span>
        </button>
      )}
    </>
  );
}
