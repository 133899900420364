import React from "react";

const DatePickerArrowLeft = ({ className }) => (
  <svg
    width="9"
    className={className}
    height="14"
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="2"
      d="M8 1L2 7l6 6"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default DatePickerArrowLeft;
