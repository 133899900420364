import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';

import React, { useCallback } from "react";

import ImagePreview from './ImagePreview';

const DropZone = ({ onUpload, files, onRemove, onRotate, uploadMessage }) => {

    const readFile = (file, onUpload) => {
        const reader = new FileReader();

        if (file.type === 'image/heic') {

            heic2any({
                blob: file,
                toType: "image/png"
            })
                .then((conversionResult) => {
                    const fileName = file.name.substring(0, file.name.lastIndexOf('.')) + '.png';
                    const result = new File([conversionResult], fileName);
                    console.log(result);
                    reader.onload = event => {
                        onUpload(result, btoa(event.target.result));
                    }
                    reader.readAsBinaryString(result);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            reader.onload = event => {
                onUpload(file, btoa(event.target.result));
            }
            reader.readAsBinaryString(file);
        }
    }

    const onDrop = useCallback(
        acceptedFiles => {
            acceptedFiles.forEach(file => readFile(file, onUpload));
        },
        [onUpload],
    );

    const { getRootProps, getInputProps, } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png', '.bmp', '.heic', '.heif']
        }
    });

    return <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {(files.length === 0 || files.filter(f => !f.deleted).length === 0) && <div className="dropzone-inputLabel">{ uploadMessage }</div>}
        <ImagePreview files={files} onRemove={onRemove} onRotate={ onRotate} />
    </div>;
}

export default DropZone;