import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import LockSvg from 'components/svg/Lock'
import TableCellOverlay from './TableCellOverlay'

const TableCellBasic = ({
  cellValue,
  noWrapClass,
  isReportAuthor,
  isReportNumber,
  isConfidential,
  isReportType,
  status,
  caseId,
  rowPrefix,
}) => {

  if (isReportType && caseId) {
    return (
      <td className='table__body-cell'>
        <div className='table__body-inset'>
          {cellValue}
        </div>
        <TableCellOverlay caseId={caseId} status={status} rowPrefix={rowPrefix} />
      </td>
    )
  }

  return (
    <td className="table__body-cell">
      <div
        className={classnames(
          'table__body-inset',
          { 'table__body-inset--no-wrap': noWrapClass },
          { 'table__body-inset--report_author': isReportAuthor }
        )}
      >
        <span style={{ width: isReportNumber && isConfidential ? '3rem' : 'auto' }}>{cellValue}</span>
        {isReportNumber && isConfidential && (
          <span style={{ paddingLeft: '.5rem' }}>
            <LockSvg className="table__body-lock-svg" svgcolor="#C1C9CC" message="baseTable.confidential" />
          </span>
        )}
      </div>
    </td>
  )
}

TableCellBasic.propTypes = {
  cellValue: PropTypes.string,
  noWrapClass: PropTypes.bool,
  isReportAuthor: PropTypes.bool,
  isReportNumber: PropTypes.bool,
  isConfidential: PropTypes.bool,
  isReportType: PropTypes.bool,
  status: PropTypes.string,
  caseId: PropTypes.string,
  rowPrefix: PropTypes.string,
}

export default TableCellBasic
