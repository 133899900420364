import React from "react";
import CustomDatePicker from "./CustomDatePicker";

const computeMinDate = (minDate, allowsPastDates) => {
  if (minDate !== undefined) {
    return minDate;
  }
  return allowsPastDates ? null : new Date();
};

const DatePickerFilterComponent = ({
  selectedDate,
  onDateChange,
  placeholder,
  allowsPastDates = true,
  minDate,
}) => {
  const computedMinDate = computeMinDate(minDate, allowsPastDates);

  return (
    <CustomDatePicker
      selected={selectedDate}
      onChange={(date) => onDateChange(date || null)}
      placeholderText={placeholder}
      minDate={computedMinDate}
      isDateRangeFilter
    />
  );
};

export default DatePickerFilterComponent;
