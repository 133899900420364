import React from "react"
import ReactDOM from 'react-dom'
import "normalize.css/normalize.css";
import "./assets/styles/main.scss"
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import configureStore from './redux/configureStore'
import { Provider as ReduxProvider } from 'react-redux'
import { loadCookiesScript } from "utils/cookies-utils"

export const store = configureStore()

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: 'SERVICE_WORKER_INIT' }),
  onUpdate: registredServiceWorker => store.dispatch({ type: 'SERVICE_WORKER_UPDATE', payload: registredServiceWorker }),
})

loadCookiesScript()
