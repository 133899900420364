import React from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import CalendarSvg from "components/svg/Calendar";
import classNames from "classnames";
import DatePickerArrowLeft from "components/svg/DatePickerArrowLeft";
import DatePickerArrowRight from "components/svg/DatePickerArrowRight";
import { getLocaleFromLanguage } from "utils/datePickerLocaleSetup";

const CustomDatePicker = ({
  selected,
  onChange,
  placeholderText,
  isDateRangeFilter,
  minDate,
  ...rest
}) => {
  const language = useSelector((state) => state.language);
  const locale = getLocaleFromLanguage(language);

  const longLocalizedDate = "P";

  return (
    <div className="input-shell">
      <div
        className={classNames("input-shell__container", {
          "date-range-picker": isDateRangeFilter,
        })}
      >
        <CalendarSvg className="input-shell__svg input-shell__svg--left input-shell__svg--calendar" />
        <DatePicker
          className="input__element input__element--use-icon-left"
          calendarClassName="date-picker"
          selected={selected}
          onChange={onChange}
          placeholderText={placeholderText}
          minDate={minDate}
          locale={locale}
          dateFormat={longLocalizedDate}
          previousMonthButtonLabel={
            <DatePickerArrowLeft className="custom-date-picker-arrows" />
          }
          nextMonthButtonLabel={
            <DatePickerArrowRight className="custom-date-picker-arrows" />
          }
          {...rest}
        />
      </div>
    </div>
  );
};

export default CustomDatePicker;
